<template>
    <div>
        <div class="title">
            <h2>{{ $t('ninhos.title') }}</h2>
        </div>
        <div class="content">
            <Loading :active="loading" />
            <div class="nota">
                <i class="fa fa-info-circle"></i>
                {{ $t('ninhos.nota') }}
            </div>
            <div v-for="(p, k) in ninhos" :key="k">
                <div v-if="p.pokemon && p.pokemon.length">
                    <div class="coords pokemon" v-for="(c, ck) in p.coordenadas" :key="ck">
                        <div class="flag">
                            <img :src="getPais(c)" style="margin-right: 5px" @error="getImage" />
                        </div>
                        <div class="coordenadas">
                            {{ c.coords }}
                        </div>
                        <div class="coord-btn">
                            <vue-flip v-if="c.coords !== '???' && c.coords !== 'global'" active-click width="100px"
                                height="45px" class="btn-content">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopy(c.coords)">
                                        {{ $t('locais.copiar') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopy(c.coords)">=)</div>
                                </template>
                            </vue-flip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Loading from '../components/Loading'
import VueFlip from 'vue-flip'
import dados from '../components/Pogo/shiny'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
    components: {
        Loading,
        VueFlip,
    },
    data: () => ({
        ninhos: [],
        inicio: null,
        fim: null,
        loading: false
    }),
    methods: {
        moment,
        async getVideos() {
            this.loading = true
            const ninhos = await axios(`${this.$url}/cat/ninhos`).catch(() => {
                alert('Aconteceu um erro!')
                this.loading = false
            })
            this.ninhos = ninhos.data.content.map(i => {
                i.open = false
                return i
            })
            this.fim = ninhos.data.fim
            this.loading = false
        },
        getPais(c) {
            if (c.pais2) {
                return c.pais
            }
            if(c.pais) {
                return `/assets/flags/${c.pais}.png`
            }
        },
        getSrc(i) {
            let v
            if (i.value2) {
                v = `${this.leftPad(i.value2, 3)}_00`
            } else if (i.value5) {
                v = `${this.leftPad(i.pokemon, 3)}_${i.value5}`
            } else
                if (i.numero) {
                    let nn
                    if (i.pokemon.match(/Alola/)) {
                        nn = '.fALOLA'
                    } else if (i.pokemon.match(/Outono/)) {
                        nn = '.fAUTUMN'
                    } else if (i.pokemon.match(/Primavera/)) {
                        nn = '.fSPRING'
                    } else if (i.pokemon.match(/Verão/)) {
                        nn = '.fSUMMER'
                    } else if (i.pokemon.match(/Inverno/)) {
                        nn = '.fWINTER'
                    }
                     else if (i.pokemon.match(/Galarian/)) {
                        nn = '.fGALARIAN'
                    } else {
                        if (i.numero == 327) {
                            nn = '.f00'
                        } else if (i.numero == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else if (i.numero == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else {
                            nn = ''
                        }
                    }
                    v = i.numero + nn
                }
            const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
            if (!i.value4) {
                return `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
            } else {
                return i.value4
            }
        },
        getCopy(coord) {
            this.$copyText(coord).then(
                () => null,
                () => alert('Erro')
            )
        },
        setShiny(dex) {
            return dados
                .filter(i => i.dex === Number(dex) && i.dex !== 618 && i.dex !== 562)
                .some(i => i.shiny_released || i.released_date)
        },
        getImage(e) {
            e.target.src = require('../assets/pokemon/0.png')
        },
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Ninhos'
        })
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.getVideos()
    }
}
</script>

<style lang="scss" scoped>
.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

.pokemon-img {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pokemon {
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
    min-height: 60px;
    padding: 5px;
}

.poke-title {
    display: flex;
    align-items: center;

    .pokemon-name {
        font-size: 14px;
        font-weight: bold;
        color: #2c3e50;
    }
}

.pokemon-count {
    font-size: 12px;
    color: #fff;
    background-color: #673bb7;
    padding: 5px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 12px;
}

.coords {
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: space-around;

    img {
        width: 20px;
    }

    .flag {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .coordenadas {
        flex: 5;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        color: #2c3e50;
    }
}

.shiny {
    background: #ffc107;
    color: rgb(255, 255, 255);
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 8px;
    height: 14px !important;
}

.pokemon-count {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

.igg {
    max-height: 40px;
    max-width: 40px;
}

.nota {
    font-size: 14px;
    color: #2c3e50;
    border: 1px solid #673bb7;
    background: #ffc107;
    //background-color: #66ff07;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    /* position: sticky;
  top: 0; */
    z-index: 2;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
